import React from 'react';
import { Root } from './style';

import { formatNumber, useSiteData } from 'utils';
import { useWeb3 } from 'web3';

import PageHeader from 'components/Common/PageHeader';
import JoinTheCommunities from 'components/Common/JoinTheCommunities';
import RawHTML from 'components/Common/RawHTML';

import vars from 'variables';

import Introduction from './Introduction';

const Whitepaper = ({ pageContext: { contractFeatures, taxes } }) => {
  const { whitepaper } = useSiteData().pages;
  const { bsc } = useWeb3();

  return (
    <Root className="body-width">
      <PageHeader page="whitepaper" />
      <div className="wrapper">
        <Introduction className="mobile" />
        <div className="tokenomics">
          <h2>Tokenomics</h2>
          <section>
            <RawHTML html={whitepaper.tokenomics.replace(/\n/g, '<br/>')} />
            <a
              className="button blue bsc"
              href={vars.links.contract}
              target="_blank"
              rel="noreferrer"
            >
              BscScan
            </a>
            <a
              className="button blue"
              href={vars.links.contractCode}
              target="_blank"
              rel="noreferrer"
            >
              Contract
            </a>
          </section>
          <section>
            <h3>Key stats</h3>
            <div>
              Reward Pool:&nbsp;
              <span>{formatNumber(bsc.rewardPoolBNB)} BNB</span>
              <br />
              Reward Pool Cap:&nbsp;
              <span>
                {formatNumber(
                  bsc.rewardPoolHardcap,
                  bsc.rewardPoolHardcap % 1 === 0 ? 0 : 1
                )}{' '}
                BNB
              </span>
              <br />
              Total BNB Paid:&nbsp;
              <span>{formatNumber(bsc.totalClaimedBNB)} BNB</span>
              <br />
              Market Cap:&nbsp;
              <span>
                $
                {formatNumber(
                  Math.round(
                    bsc.hodlUsdPrice * (10000000000 - bsc.burnWalletHODL)
                  )
                )}
              </span>
              <br />
              Hodlers:&nbsp;
              <span>{formatNumber(bsc.hodlers, 0)}</span>
              <br />
              Liquidity Pool:&nbsp;
              <span>${formatNumber(Math.round(bsc.liquidityPoolUSD))}</span>
              <br />
              Liquidity Lock:&nbsp;
              <span>Jan 2280</span>
              <br />
            </div>
            <RawHTML html={whitepaper.additionalTokenInfo} />
          </section>
          <section className="taxes">
            {taxes.map((tax) => (
              <div key={tax.id}>
                <h3>
                  {tax.name} <span>({tax.totalPercentage}%)</span>
                </h3>
                {
                  <RawHTML
                    html={tax.breakdown
                      .replace(/[\d\.]+\s*%/g, (m) => `<span>${m}</span>`)
                      .replace(/\n/g, '<br/>')}
                  />
                }
              </div>
            ))}
          </section>
        </div>
        <Introduction className="desktop" />
        {contractFeatures.map((feature) => (
          <div className="contract-feature" key={feature.id}>
            <h2>{feature.name}</h2>
            <RawHTML html={feature.explanation} />
          </div>
        ))}
      </div>
      <JoinTheCommunities />
    </Root>
  );
};

export default Whitepaper;
